export const config = {
    title: 'Mayan',
    name: "MAYAN",
    image: '',
    logo: 'assets/images/mayan/Mayan logo 1.png',
    DOMAIN_NAME : 'https://www.mayanhandicrafts.in',
    USER_ID : 18,
    STORE_ID: 1,
    ORGANIZATION_ID:1,
    default_product_image: "",
    product_path:"/productListing",
    showTableBookingBtn:false,
    noImageAvailable:"assets/images/mayan/NoImage.png",
    pattern1: "!@#$%^",
    pattern2: "8172",
    pattern3: "mayan",

    HOME_SETTINGS: {
      banner: [
        {
          name: "Home Banner",
          image: "assets/images/vendor/banner.png",
          quote: "<h3>Good Food <br> Good Feelings</h3><p>with <span>TAMS</span></p>",
          button: {
            name: "CHECK PRODUCTS",
            link: "product"
          }
        }
      ],
      about_st: [
        {
          title: "Excellent Craftsmenship",
          // image: "assets/images/tams/home1.svg",
          image: "assets/images/mayan/craftmanship.png",
          // text: "Get your Flour mixes delivered straight to your home by ordering here"
          text: "Get your sculptures in quality, beautiful and has been very carefully made"
        },
        {
          title: "Finest Sculptures",
          // image: "assets/images/vendor/home3.svg",
          image: "assets/images/mayan/finest.png",
          // text: "iLY’s state of the art technologies provides the longer shelf life for iLY flour mixes for upto six months"
          text: "Fine quality sculptures at reasonable price"
        },
        {
          title: "100% Customized",
          // image: "assets/images/vendor/home4.svg",
          image: "assets/images/mayan/100-percent.png",
          // text: "Just add the required amount of water and ferment for 12 hours and your iLY idly and dosa batter is ready"
          text: "Mayan guarantees the 100% customized sculptures"
        },
        {
          title: "International Exports",
          // image: "assets/images/vendor/home2.svg",
          image: "assets/images/mayan/international.png",
          // text: "iLY uses the best raw materials to prepare the best ready mix available in the market"
          text: "High quality international products were exported"

        },
      ],
      gutters: [
        {
          image: "assets/images/mayan/BUDDHA.jpg"
        },
        {
          image: "assets/images/mayan/ELEPHANT - 2.jpg"
        },
        {
          image: "assets/images/mayan/RELAXING GANESHA.jpg"
        },
        {
          image: "assets/images/mayan/CRANE.jpg"
        }
      ],
      testimonials: [
        {
          title: "Mayan",
          comment: "The sculpture is said to be an art of intelligence; which is why every block of stone is said to have a statue inside it, and it is the task of the sculptor to discover it. ",
          name: ""
        }
      ],
      float_button: {
        show: false,
        image: "assets/images/tams/online.svg",
        link: "/product"
      }
    },
    menu: [
      {
        id: "home",
        label: "Home",
        link: "/"
      },
      {
        id: "products",
        label: "FLOUR MIXES",
        link: "product"
      },
      {
        id: "about",
        label: "About Us",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      }
    ],
    footer: [
      {
        id: "about",
        label: "About Us",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      },
      // {
      //   id: "faq",
      //   label: "FAQ",
      //   link: "FAQs"
      // },
      {
        id: "termsandconditions",
        label: "Terms & conditions",
        link: "terms-and-condition"
      },
      {
        id: "privacypolicy",
        label: "Privacy Policy",
        link: "privacy-policy"
      },
      {
        id: "cancellationPolicy",
        label: "Cancellation Policy",
        link: "cancellation-policy"
      },
      {
        id: "shippinganddelivery",
        label: "Shipping & Delivery",
        link: "shipping-and-delivery"
      },
      {
        id: "returnPolicy",
        label: "Return & Exchange Policy",
        link: "return-policy"
      }
    ]
  };
